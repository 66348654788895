<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑套餐"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="套餐名称">
          <a-input
            :disabled="isSelfChoose"
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入套餐名称' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="项目代码">
          <a-input
            v-decorator="['code', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入项目代码' },
                { max: 20, message: '最多20个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="拼音码">
          <a-input
            v-decorator="['pinyin', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入拼音码' },
                { max: 20, message: '最多20个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="所属殡仪馆">
          <a-select
            :loading="loadingFuneralHomeOptions"
            mode="multiple"
            @change="handleChangeFuneralHome"
            @deselect="handleDeselectFuneralHome"
            v-decorator="['funeral_home_ids', {
              rules: [{ required: true, message: '请选择所属殡仪馆' }]
            }]"
          >
            <a-select-option
              v-for="home in funeralHomeOptions"
              :key="home.id"
              :value="home.id"
            >
              {{ home.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="关联厅房">
          <a-select
            mode="multiple"
            v-decorator="['hall_ids', {
              rules: [{ required:true,message: '请选择厅房' }],
            }]"
          >
            <a-select-option
              v-for="hall in hallOptions"
              :key="hall.id"
              :value="hall.id"
            >
              {{ hall.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="价格(元)" v-if="!isSelfChoose">
          <a-input-number
            style="width: 100%;"
            :min="0.00"
            :max="10000000"
            :step="0.01"
            :precision="2"
            v-decorator="['price', {
              rules: [
                { required: true, message: '请输入价格' },
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="图片">
          <div
            style="line-height: 20px;margin-top: 10px;margin-bottom: 10px"
          >支持png/jpg图片格式，建议尺寸宽高比180:180px，最多1张
          </div>
          <upload-image
            :is-add-watermark="isAddWatermark"
            accept=".jpg,.jpeg,.png"
            :max-count="1"
            :default-file-list="defaultFileList"
            @change="handleUploadImage"
            v-decorator="['attachments',{
              rules:[
                {validator: checkAttachments}
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="详情图片">
          <div
            style="line-height: 20px;margin-top: 10px;margin-bottom: 10px"
          >支持png/jpg图片格式，建议尺寸宽高比180:180px，最多5张
          </div>
          <upload-image
            :is-add-watermark="isAddWatermark"
            accept=".jpg,.jpeg,.png"
            :max-count="5"
            :multiple="true"
            :default-file-list="defaultDetailFileList"
            @change="handleUploadDetailImage"
            v-decorator="['detail_attachments',{
              rules:[
                {validator: checkDetailAttachments}
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="介绍">
          <a-textarea
            v-decorator="['remark', {
              normalize: this.$lodash.trim,
              rules: [
                { max: 300, message: '最多300个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import UploadImage from '@/components/Upload/Image'
import { updateTenantPackage } from '@/api/package_management'
import { findHallWithFuneralHomeOptions } from '@/api/hall'
import { findFuneralHomeOptions } from '@/api/funeral_home'

export default {
  name: 'EditTenantPackage',
  components: {
    UploadImage
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    record: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'tenant_package_edit' }),
      hallOptions: [],
      funeralHomeOptions: [],
      loadingFuneralHomeOptions: false,
      submitting: false,
      isAddWatermark: false,
      attachments: [], // 图片
      detailAttachments: [], // 详情图片
      defaultFileList: [],
      defaultDetailFileList: [],
      data: {},
      isSelfChoose: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFuneralHomeOptions()
    this.initFormData()
  },
  methods: {
    initFormData() {
      this.defaultFileList = []
      if (this.record.images && this.record.images.length > 0) {
        this.record.images.forEach((item, index) => {
          this.defaultFileList.push({
            uid: index,
            name: 'image.png',
            status: 'done',
            url: item.url,
            response: item.response
          })
        })
      }
      this.defaultDetailFileList = []
      if (this.record.detail_images && this.record.detail_images.length > 0) {
        this.record.detail_images.forEach((item, index) => {
          this.defaultDetailFileList.push({
            uid: index,
            name: 'image.png',
            status: 'done',
            url: item.url,
            response: item.response
          })
        })
      }
      this.isSelfChoose = this.record.is_self_choose
      this.data = Object.assign({}, {
        name: this.record.name,
        price: this.record.price,
        remark: this.record.remark,
        funeral_home_ids: this.record.funeral_home_ids && this.record.funeral_home_ids.length > 0 ? this.record.funeral_home_ids : undefined,
        hall_ids: this.record.hall_ids && this.record.hall_ids.length > 0 ? this.record.hall_ids : undefined,
        code: this.record.code,
        pinyin: this.record.pinyin
      })
      this.fetchHallOptions(this.data.funeral_home_ids)
      this.$nextTick(() => {
        this.form.setFieldsValue(this.data)
        this.attachments = this.defaultFileList
        this.detailAttachments = this.defaultDetailFileList
      })
    },

    // 加载厅房选项
    fetchHallOptions(homeIds) {
      if (homeIds && homeIds.length > 0) {
        findHallWithFuneralHomeOptions({ funeral_home_ids: homeIds, package_id: this.record.id }).then((res) => {
          this.hallOptions = res.data.options
          if (res.data.default_options && res.data.default_options.length > 0) {
            this.hallOptions = this.$lodash.concat(res.data.default_options, res.data.options)
            this.hallOptions = this.$lodash.uniqWith(this.hallOptions, this.$lodash.isEqual)
          }
        })
      } else {
        this.hallOptions = []
      }
    },

    fetchFuneralHomeOptions() {
      this.loadingFuneralHomeOptions = true
      findFuneralHomeOptions({ package_id: this.record.id }).then((res) => {
        this.funeralHomeOptions = res.data.options
        if (res.data.default_options && res.data.default_options.length > 0) {
          this.funeralHomeOptions = this.$lodash.concat(res.data.default_options, res.data.options)
          this.funeralHomeOptions = this.$lodash.uniqWith(this.funeralHomeOptions, this.$lodash.isEqual)
        }
      })
      this.loadingFuneralHomeOptions = false
    },

    handleChangeFuneralHome(homeIds) {
      const funeralHomeIds = this.form.getFieldValue('funeral_home_ids')
      if (!funeralHomeIds || (homeIds.length > funeralHomeIds.length)) {
        this.fetchHallOptions(homeIds)
      }
    },

    handleDeselectFuneralHome(homeId) {
      const hallIds = this.form.getFieldValue('hall_ids')
      const vm = this
      const funeralHomeIds = this.form.getFieldValue('funeral_home_ids')
      let isRelationHall = false
      this.$lodash.find(this.hallOptions, function(obj) {
        if (!isRelationHall && homeId === obj.funeral_home_id) {
          if (hallIds.includes(obj.id)) {
            vm.$warning({
              title: '当前殡仪馆被占用，请先解除相关厅房关联',
              content: ''
            })
            isRelationHall = true
          }
        }
      })
      if (isRelationHall) {
        this.$nextTick(() => {
          vm.form.setFieldsValue({ funeral_home_ids: funeralHomeIds })
        })
      }
      this.$nextTick(() => {
        this.fetchHallOptions(this.form.getFieldValue('funeral_home_ids'))
      })
    },

    handleUploadImage(fileList) {
      this.attachments = fileList
    },

    // 检查照片
    checkAttachments(rule, value, callback) {
      for (const key in this.attachments) {
        if (this.attachments[key] && this.attachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('照片上传错误')
          return
        }
      }

      callback()
    },

    handleUploadDetailImage(fileList) {
      this.detailAttachments = fileList
    },

    // 检查照片
    checkDetailAttachments(rule, value, callback) {
      for (const key in this.detailAttachments) {
        if (this.detailAttachments[key] && this.detailAttachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('照片上传错误')
          return
        }
      }

      callback()
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      if (this.$lodash.find(this.attachments, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }

      if (this.$lodash.find(this.detailAttachments, { status: 'uploading' })) {
        this.$warning({
          title: '详情图片尚未上传完成',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const editData = {
            ...values,
            attachments: this.$lodash.map(this.attachments, 'response'),
            detail_attachments: this.$lodash.map(this.detailAttachments, 'response')
          }
          updateTenantPackage(this.record.id, editData).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
